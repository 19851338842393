import React from 'react';
import {
  FiFacebook,
  FiYoutube,
  FiLinkedin,
  FiInstagram,
  FiTwitter,
} from 'react-icons/fi';

interface SocialIcon {
  icon: SocialIconEnum;
}

export enum SocialIconEnum {
  facebook = 'FiFacebook',
  youtube = 'FiYoutube',
  linkedin = 'FiLinkedin',
  instagram = 'FiInstagram',
  twitter = 'FiTwitter',
}

const SocialIcon: React.FC<SocialIcon> = (props) => {
  const icon = props.icon;
  switch (icon) {
    case SocialIconEnum.facebook:
      return <FiFacebook size={24} />;
    case SocialIconEnum.youtube:
      return <FiYoutube size={24} />;
    case SocialIconEnum.linkedin:
      return <FiLinkedin size={24} />;
    case SocialIconEnum.instagram:
      return <FiInstagram size={24} />;
    case SocialIconEnum.twitter:
      return <FiTwitter size={24} />;
    default:
      return null;
  }
};

export default SocialIcon;
