import React from 'react';
import { css } from '@emotion/core';
import {
  injectIntl,
  WrappedComponentProps,
  FormattedMessage,
} from 'react-intl';
import AppleStore from '../assets/images/apple_store.svg';
import GooglePlay from '../assets/images/google_play.svg';

const APP_STORE_LINKS = {
  de: 'https://cara.app.link/455F4gcGNV',
  en: 'https://cara.app.link/vbvd3bev6y',
};

const GOOGLE_PLAY_LINKS = {
  de: 'https://cara.app.link/LLb15wAGNV',
  en: 'https://cara.app.link/play',
};

const styles = {
  row: css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  `,
  button: css`
    display: block;
    margin-bottom: 1rem;
  `,
  img: css`
    width: 156px;
    height: 47px;
    max-width: 100%;
    margin-bottom: 0;
  `,
  text: css`
    margin-bottom: 1rem;
  `,
};

interface OwnProps {
  text?: boolean;
  align?: 'flex-start' | 'center' | 'flex-end';
  column?: boolean;
}

type Props = OwnProps & WrappedComponentProps;

const AppDownloadButtons: React.FC<Props> = ({
  column,
  text = true,
  align = 'flex-start',
  intl: { locale },
}) => {
  const handleDownloadButtonClicks = (
    event: React.SyntheticEvent<HTMLAnchorElement>
  ) => {
    const href = event.currentTarget.href;
    switch (href) {
      case APP_STORE_LINKS.en:
      case APP_STORE_LINKS.de:
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        ga('send', 'event', {
          eventCategory: 'App Download Button Clicks',
          eventAction: 'click',
          eventLabel: 'iOS app download button clicked',
          transport: 'beacon',
        });
        break;
      case GOOGLE_PLAY_LINKS.en:
      case GOOGLE_PLAY_LINKS.de:
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        ga('send', 'event', {
          eventCategory: 'App Download Button Clicks',
          eventAction: 'click',
          eventLabel: 'Android app download button clicked',
          transport: 'beacon',
        });
        break;
    }
  };

  // temporarily don't show download buttons in German pages
  if (locale === 'de') {
    return null;
  }

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: align,
      }}
    >
      {text && (
        <div css={styles.text}>
          <FormattedMessage
            id="hero.startFree"
            defaultMessage="Jetzt kostenlos starten:"
          />
        </div>
      )}
      <div css={[styles.row, column && { flexDirection: 'column' }]}>
        <a
          className="download-app-button"
          onClick={handleDownloadButtonClicks}
          href={APP_STORE_LINKS[locale as 'de' | 'en'] || APP_STORE_LINKS.en}
          title="Download Cara Care on App Store"
          css={[styles.button, { marginRight: column ? 0 : 24 }]}
        >
          <img
            src={AppleStore}
            css={styles.img}
            alt="Download on the App Store"
          />
        </a>
        <a
          className="download-app-button"
          onClick={handleDownloadButtonClicks}
          href={
            GOOGLE_PLAY_LINKS[locale as 'de' | 'en'] || GOOGLE_PLAY_LINKS.en
          }
          title="Download Cara Care on Google Play"
          css={styles.button}
        >
          <img src={GooglePlay} css={styles.img} alt="Get it on Google Play" />
        </a>
      </div>
    </div>
  );
};

export default injectIntl(AppDownloadButtons);
