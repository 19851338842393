import React from 'react';
import { Global } from '@emotion/core';
import global from '../../styles/global';

import LayoutHeader from './LayoutHeader';
import Footer from '../Footer';
import NewFooter from '../Homepage/Footer';
import '../../styles/blog/app.scss';
import BlueFooter from '../Homepage/BlueFooter';
import { useIntl } from 'react-intl';
import { OpenSansParagraph } from '../common/typography';

export interface LayoutProps {
  hideLanguageSwap?: boolean;
  isNewLayout?: boolean;
  isBlueFooter?: boolean;
  isDarkHeader?: boolean;
  noHeader?: boolean;
  noFooter?: boolean;
  simpleHeader?: boolean;
  simpleFooter?: boolean;
  hideCallToAction?: boolean;
  prescriptionLink?: string;
}

type Props = LayoutProps;

const Layout: React.FC<Props> = ({
  children,
  hideLanguageSwap = false,
  isNewLayout = false,
  isDarkHeader = false,
  noHeader = false,
  noFooter = false,
  simpleHeader = false,
  simpleFooter = false,
  hideCallToAction = false,
  isBlueFooter = false,
  prescriptionLink,
}) => {
  const intl = useIntl();

  const renderFooter = () => {
    if (isNewLayout) {
      return <NewFooter simpleFooter={simpleFooter} />;
    }

    if (isBlueFooter) {
      return <BlueFooter />;
    }

    return <Footer />;
  };

  return (
    <>
      <Global styles={global} />

      {noHeader ? null : (
        <LayoutHeader
          {...{
            isNewLayout,
            hideLanguageSwap,
            isDarkHeader,
            hideCallToAction,
            prescriptionLink,
            simpleHeader,
          }}
        />
      )}
      <main
        css={{
          flex: '1 0 auto',
          margin: '10px 0',
          transition: 'margin-top 0.5s',
          marginTop: 'calc(10rem + 30px)',
          '@media (max-width: 768px)': {
            marginTop:
              intl.locale === 'de'
                ? 'calc(13rem + 60px)'
                : 'calc(10rem + 60px)',
          },
        }}
      >
        {children}
      </main>
      {noFooter ? null : renderFooter()}
    </>
  );
};

export default Layout;
