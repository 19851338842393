import React from 'react';
import { css } from '@emotion/core';
import IconButton from '../IconButton';
import ExternalLink from '../Link';
import SocialIcon, { SocialIconEnum } from './SocialIcon';
import { ContentfulLink } from '../../graphql-types';

const styles = {
  root: css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  `,
  link: css`
    margin-right: 15px;

    &:last-of-type {
      margin-right: 0;
    }
  `,
};

const Link = IconButton.withComponent(ExternalLink);

interface Props {
  socialLinks: ContentfulLink[];
}

const SocialMediaLinks: React.FC<Props> = ({ socialLinks, ...rest }) => (
  <div css={styles.root} {...rest}>
    {socialLinks.map((socialLink) => (
      <Link
        key={socialLink.id}
        to={socialLink.url}
        title={socialLink.text}
        css={styles.link}
      >
        <SocialIcon icon={socialLink.icon as SocialIconEnum} />
      </Link>
    ))}
  </div>
);

export default SocialMediaLinks;
