import React from 'react';
import { css } from '@emotion/core';
import { graphql, useStaticQuery } from 'gatsby';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col } from 'emotion-flex';
import Link from './Link';
import AppDownloadButtons from './AppDownloadButtons';
import SocialMediaLinks from './SocialMediaLinks';
import presets from '../styles/presets';
import { BASE_COLOR } from '../styles/theme';
import { ContentfulFooterConnection } from '../graphql-types';

const styles = {
  root: css`
    padding-top: 48px;
    color: #f6f6f6;
    font-size: 14px;
    background-color: ${BASE_COLOR};
  `,
  heading: css`
    margin-bottom: 1rem;
    font-size: 17px;
    font-weight: 500;
    line-height: 30px;
    color: #f6f6f6;
    opacity: 0.6;
  `,
  textLeft: css`
    p {
      white-space: pre-line;
      margin-top: 0;
      font-size: 14px;
      color: #f6f6f6;
      line-height: 24px;
    }
  `,
  linksList: css`
    display: flex;
    flex-direction: column;

    a,
    span {
      margin-bottom: 1rem;
      color: inherit;
      text-decoration: none;
    }

    a {
      font-size: 17px;
      font-weight: 500;
      color: #f6f6f6;
      line-height: 28px;
    }
  `,
  legal: css`
    font-size: 14px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    padding: 24px 0;
  `,
  space: css`
    margin: 20px 0;

    ${presets.lg} {
      margin: 0;
      margin-bottom: 48px;
    }
  `,
};

type Props = WrappedComponentProps;

const Footer: React.FC<Props> = ({ intl: { locale } }) => {
  const {
    footer,
  }: { footer: ContentfulFooterConnection } = useStaticQuery(graphql`
    query footerQuery {
      footer: allContentfulFooter {
        nodes {
          node_locale
          footerLeftAreaTitle
          leftLinksArea {
            id
            text
            url
            isDisplayed
          }
          footerCenterAreaTitle
          centerLinksArea {
            id
            text
            url
            isDisplayed
          }
          footerRightAreaTitle
          rightLinksArea {
            id
            text
            url
            isDisplayed
          }
          contactEmail
          copyrights
          socialLink {
            id
            url
            text
            icon
            isDisplayed
          }
        }
      }
    }
  `);
  const localData = footer.nodes.find((node) => node.node_locale === locale);

  return (
    <footer css={styles.root}>
      <Container>
        <Row>
          <Col xs={12} lg={4} css={styles.space}>
            <div css={styles.linksList}>
              <span css={styles.heading}>{localData.footerLeftAreaTitle}</span>
              {localData.leftLinksArea &&
                localData.leftLinksArea
                  .filter((link) => link.isDisplayed)
                  .map((link) => (
                    <Link key={link.id} to={link.url}>
                      {link.text}
                    </Link>
                  ))}
            </div>
          </Col>
          <Col xs={12} lg={4} css={styles.space}>
            <div css={styles.linksList}>
              <span css={styles.heading}>
                {localData.footerCenterAreaTitle}
              </span>
              {localData.centerLinksArea &&
                localData.centerLinksArea
                  .filter((link) => link.isDisplayed)
                  .map((link) => (
                    <Link key={link.id} to={link.url}>
                      {link.text}
                    </Link>
                  ))}
            </div>
          </Col>
          <Col xs={12} lg={4} css={styles.space}>
            <div css={styles.linksList}>
              <span css={styles.heading}>{localData.footerRightAreaTitle}</span>
              {localData.rightLinksArea &&
                localData.rightLinksArea
                  .filter((link) => link.isDisplayed)
                  .map((link) => (
                    <Link key={link.id} to={link.url}>
                      {link.text}
                    </Link>
                  ))}
              <a href={`mailto:${localData.contactEmail}`}>
                {localData.contactEmail}
              </a>
            </div>
            <SocialMediaLinks
              socialLinks={localData.socialLink}
              css={{ marginBottom: 16 }}
            />
            <AppDownloadButtons text={false} column />
          </Col>
          <Col xs={12}>
            <div css={[styles.heading, styles.legal]}>
              © {new Date().getFullYear()} {localData.copyrights}
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default injectIntl(Footer);
