import React from 'react';
import Layout, { LayoutProps } from './Layout';

const withLayout = <P extends {}>(
  Component: React.ComponentType<P>,
  layoutProps: LayoutProps = {}
): React.FC<P> => {
  const WithLayout = (props: P) => (
    <Layout {...layoutProps}>
      <Component {...(props as P)} />
    </Layout>
  );
  return WithLayout;
};

export default withLayout;
